<template>
  <div>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
        :height="300"
        :width="400"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-card>
      <v-card-title class="grid-close">
        <b >
          Configurar {{ product.name }}
          <v-chip color="gray" class="mb-2">
            {{ product.price | toCurrency }}
          </v-chip>
        </b>

        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <addon-skeleton v-if="loading" />

        <v-container v-if="!loading" fluid>
          <v-row>
            <v-col cols="12">
              <p>Nombre de la sección complementa</p>

              <input
                class="control-input"
                type="text"
                v-model="newAddon.displayName"
                placeholder="Ingresar el nombre"
              />

              <v-btn
                class="mt-2"
                elevation="0"
                block
                @click="addNewAddon"
                color="primary"
              >
                <v-icon right dark class="mr-3">fas fa-plus</v-icon>
                Crear sección
              </v-btn>
            </v-col>
            <v-col cols="12" class="btn-order">
              <v-btn
                color="primary"
                class="white--text mr-3"
                :disabled="addons.length < 2"
                @click="orderOptionsModal = true"
                :loading="loading"
                elevation="0"
              >
                <v-icon right dark class="mr-3"> fas fa-bars </v-icon>
                Ordenar
              </v-btn>
            </v-col>

            <!-- <v-col v-if="addons.length > 0" cols="12">
              <p class="font-weight-bold" style="font-size: 18px">
                Doble clic para trabajar una sección
              </p>
              <v-divider></v-divider>
            </v-col> -->

            <v-col cols="12">
              <v-list nav dense>
                <v-list-item-group style="width: 100%" color="primary">
                  <v-list-item
                    class="pl-3 pr-2"
                    v-for="(item, i) in addons"
                    :key="i"
                  >
                   <template default>
                    <v-list-item-content :class="{linea_b:i < addons.length - 1}" style="width: 100%">
                      <v-row>
                        <v-col cols="7">
                          <v-list-item-title
                            v-text="item.displayName"
                          ></v-list-item-title>
                        </v-col>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-col cols="2">
                              <v-btn
                                v-on="on"
                                color="primary"
                                class="white--text"
                                @click="moveAndShow(item)"
                                small
                                elevation="0"
                                >Editar
                                <!-- <v-icon>fa fa-edit</v-icon> -->
                              </v-btn>
                            </v-col>
                          </template>
                          <span>Editar {{ item.name }}</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-col cols="2">
                              <v-btn
                                v-on="on"
                                color="red darken-4"
                                class="white--text"
                                @click="showModalDelete(item)"
                                small
                                elevation="0"
                              >
                                Eliminar
                                <!-- <v-icon>fa fa-trash</v-icon> -->
                              </v-btn>
                            </v-col>
                          </template>
                          <span>Eliminar {{ item.name }}</span>
                        </v-tooltip>
                      </v-row>
                 
                    </v-list-item-content>
                   </template>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog
      persistent
      v-if="addonToEdit"
      v-model="modalShowOptions"
      max-width="900px"
    >
      <addon-options
        :key="addonToEdit['.key']"
        @cancel="modalShowOptions = false"
        :product="product"
        :products="products"
        :addon="addonToEdit"
      ></addon-options>
    </v-dialog>

    <v-dialog v-model="orderOptionsModal" max-width="500px">
      <sort
        :key="`sort-${randomKey}`"
        @success="handleSortSuccess"
        :product="product"
        @cancel="orderOptionsModal = false"
        :addons="addons"
      ></sort>
    </v-dialog>

    <v-dialog max-width="350" v-model="modalDelete">
      <v-card>
        <v-card-title class="headline"> Confirmar acción </v-card-title>

        <v-card-text>
          ¿Está seguro que desea eliminar este elemento?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="confirmDelete()">
            Eliminar
          </v-btn>
          <v-btn color="primary" text @click="modalDelete = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapState } from "vuex";
import addonSkeleton from "../skeleton/edit-addon-skeleton";
import addonOptions from "./addon-options";
import sort from "./sort-addons";
import ledgerMixin from "@/mixins/ledgerMixin";

export default {
  name: "section-details",
  props: ["product", "products"],
  mixins: [lottieSettings, ledgerMixin],
  components: {
    lottie: Lottie,
    addonSkeleton,
    addonOptions,
    sort,
  },
  data() {
    return {
      addons: [],
      editedProduct: {},
      saving: false,
      snackbar: false,
      snackbarText: "",
      newAddon: {
        name: "",
        active: true,
        required: true,
        controlType: "radio",
        businessId: "",
        deleted: false,
        createdAt: "",
        createdBy: this.$store.state.user[".key"],
        deletedBy: "",
        min: 1,
        max: 1,
        displayName: "",
        parentProductId: "",
        parentProductName: " ",
      },
      modalDelete: false,
      itemToDelete: {},
      loading: true,
      modalShowOptions: false,
      addonToEdit: null,
      orderOptionsModal: false,
      randomKey: 0,
    };
  },
  filters: {
    toCurrency(value) {
      if (typeof value !== "number") return value;

      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });

      return formatter.format(value);
    },
  },
  computed: {
    ...mapState(["selectedBusiness"]),
  },
  methods: {
    moveAndShow(addon) {
      this.modalShowOptions = true;
      this.addonToEdit = addon;
    },
    getTime() {
      let timeArr = moment()
        .tz("America/Tegucigalpa")
        .format("YYYY, MM, DD, HH, mm, ss, 0")
        .split(",");
      return new Date(
        parseInt(timeArr[0]),
        parseInt(timeArr[1]) - 1,
        parseInt(timeArr[2]),
        parseInt(timeArr[3]),
        parseInt(timeArr[4]),
        parseInt(timeArr[5]),
        parseInt(timeArr[6])
      );
    },
    handleSortSuccess(data) {
      this.addons = data;
    },
    confirmDelete() {
      db.collection(`qrAddons`)
        .doc(this.itemToDelete[".key"])
        .update({
          deleted: true,
          active: false,
          deletedAt: fb.firestore.Timestamp.fromDate(this.getTime()),
          deletedBy: this.$store.state.user[".key"],
        })
        .then(() => {
          // this.removeLocalAddon(this.itemToDelete['.key'])
          this.snackbarText = "Complemento borrado";
          this.snackbar = true;
          this.modalDelete = false;
          this.getAddons();

          this.createLedger({
            source_id: this.itemToDelete[".key"],
            source_type: "addons",
            currentValue: {
              deleted: true,
              active: false,
              deletedAt: fb.firestore.Timestamp.fromDate(this.getTime()),
              deletedBy: this.$store.state.user[".key"],
            },
            prevValue: {},
          });
        })
        .catch((error) => {
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
        });
    },
    associateAddonToProduct: function (id) {
      this.editedProduct.addons.push(id);

      let AddonAdded = Object.assign({}, this.newAddon);
      AddonAdded[".key"] = id;

      this.newAddon.displayName = "";

      var productRef = db.collection("qrProducts").doc(this.product.id);

      return productRef
        .update({
          addons: this.editedProduct.addons,
        })
        .then(() => {
          const prevValue = this.product.addons.filter(
            (addonID) => addonID !== id
          );

          this.createLedger({
            source_id: this.product.id,
            source_type: "products",
            currentValue: {
              addons: this.product.addons,
            },
            prevValue: {
              addons: prevValue,
            },
          });
        })
        .catch((error) => console.log("error ", error));
    },
    addNewAddon: function () {
      this.newAddon.parentProductId = this.product.id;
      this.newAddon.parentProductName = this.product.name;
      this.newAddon.name = this.newAddon.displayName;
      this.newAddon.businessId = this.selectedBusiness[".key"];
      this.newAddon.createdAt = fb.firestore.Timestamp.fromDate(this.getTime());
      this.newAddon.createdBy = this.$store.state.user[".key"];
      this.newAddon.position = this.addons.length + 1;

      if (
        this.newAddon.displayName &&
        this.newAddon.businessId &&
        this.newAddon.parentProductId &&
        this.newAddon.parentProductName
      ) {
        this.saving = true;
        db.collection("qrAddons")
          .add(this.newAddon)
          .then((ref) => {
            this.associateAddonToProduct(ref.id);
            this.snackbarText = "Complemento agregado exitosamente.";
            this.snackbar = true;
            this.saving = false;
            this.getAddons();

            this.createLedger({
              source_id: ref.id,
              source_type: "addons",
              currentValue: this.newAddon,
              prevValue: {},
            });
          })
          .catch((err) => {
            console.log(err);
            this.snackbarText =
              "Ha ocurrido un error inesperado, inténtelo nuevamente";
            this.snackbar = true;
            this.saving = false;
          });
      } else {
        this.snackbarText = "Ingrese el nombre";
        this.snackbar = true;
      }
    },
    showModalDelete(element) {
      this.modalDelete = true;
      this.itemToDelete = element;
    },
    async getAddons() {
      if (this.editedProduct.addons.length > 0) {
        this.addons = [];
        this.loading = true;
        for (let i = 0; i < this.editedProduct.addons.length; i = i + 10) {
          const ids = this.editedProduct.addons.slice(i, i + 10);
          let query = await db
            .collection(`qrAddons`)
            .where(fb.firestore.FieldPath.documentId(), "in", ids)
            .where("deleted", "==", false)
            .get();

          let items = query.docs.map((e) => {
            let addon = e.data();
            // console.log(addon);
            addon[".key"] = e.id;
            return addon;
          });

          this.addons = [...this.addons, ...items];
        }
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.editedProduct = Object.assign({}, this.product);

    this.getAddons();
  },
};
</script>

<style scoped>
.btn-modal-add-tags {
  justify-content: center;
}
.add-tag-ico {
  font-size: 30px !important;
  padding: 10px;
}
.add-schedule {
  display: flex;
  justify-content: center;
}
.close-modal {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.grid-close {
  display: grid;
  grid-template-columns: auto auto;
}
.fa-times {
  color: gray;
}
.addon-config {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.336);
}
.new-addon {
  padding: 15px;
  background-color: white;
  min-height: 200px;
}
.fa-trash-alt {
  cursor: pointer;
}
p {
  padding: 0;
  margin: 0;
  color: rgba(0, 0, 0, 0.836);
  font-size: 16px;
}
.theme--light.v-list {
  border-radius: 20px !important;
  background-color: white !important;
  padding: 10px !important;
  margin-top: 15px !important;
}
.theme--light.v-text-field--filled .v-input__control .v-input__slot {
  background: white !important;
}
.new-price >>> input {
  text-align: center;
  width: 50px !important;
}
.move-class {
  animation-name: move-animation;
  animation-duration: 0.2s;
  position: absolute;
  left: 0px;
  max-width: 400px;
  top: 0px;
}
.btn-order {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@keyframes move-animation {
  0% {
    position: absolute;
    left: 10%;
    top: 10%;
  }
  10% {
    position: absolute;
    left: 9%;
    top: 9%;
  }
  20% {
    position: absolute;
    left: 8%;
    top: 8%;
  }
  30% {
    position: absolute;
    left: 7%;
    top: 7%;
  }
  40% {
    position: absolute;
    left: 6%;
    top: 6%;
  }
  50% {
    position: absolute;
    left: 5%;
    top: 5%;
  }
  60% {
    position: absolute;
    left: 4%;
    top: 4%;
  }
  70% {
    position: absolute;
    left: 3px;
    top: 3px;
  }
  80% {
    position: absolute;
    left: 2px;
    top: 2px;
  }
  90% {
    position: absolute;
    left: 1px;
    top: 1px;
  }
  100% {
    position: absolute;
    left: 0px;
    top: 0px;
  }
}
.linea_b{
  border-bottom: 1px solid rgb(228, 228, 228);
}
</style>
