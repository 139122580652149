<template>
<div>  
    <v-card>
        <v-card-title class="grid-close">
            <span class="headline">Opciones para {{addon.name}}
            </span>

             <v-btn
                style="right:10px; top:10px;"
                icon
                color="primary"
                fab
                absolute
                @click="$emit('cancel')"
                >
                <v-icon dark>fa-times</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
        
        <v-container fluid>
            <v-row>
                <v-col cols="12" sm="12" md="12">
                    <a-transfer
                    :listStyle="{
                        'height': '400px!important',
                        'width' : '45%',
                        'background' : 'white'
                    }"
                    :data-source="allProductsAddons"
                    :titles="['Todas las opciones', 'Opciones vinculadas']"
                    :target-keys="optionsSelected"
                    :selected-keys="selectedKeys"
                    :show-search="true"
                    @search="handleSearch"
                    :render="item => item.title"
                    :filter-option="filterOption"
                    @change="handleChange"
                    @selectChange="handleSelectChange"
                    :locale= "{ itemUnit: '', itemsUnit: '', notFoundContent: 'Lista vacía', searchPlaceholder: 'Buscar sección' }"
                    />
                </v-col>
            </v-row>
          
            <v-row>
                <v-col cols="12" sm="12" md="12">
                    <!-- <v-divider class="mt-3"></v-divider> -->
                    <v-row justify="end">
                        <v-btn @click="updateOptions" color="primary" class="mt-3 save-btn mr-5" dark>Aceptar</v-btn>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
        </v-card-text>
    </v-card>
</div>
    
</template>

<script>

import transfer from 'ant-design-vue/lib/transfer';

export default {
  name: "add-remove-option",
  props: ['addon', 'allOptions', 'optionsAdded'],
  components: {
    'a-transfer' : transfer
  },
  data() {
   return {      
        allProductsAddons : [],
        selectedKeys: [],
        optionsSelected : []
    }
  },

  methods: {

    handleChange(nextTargetKeys, direction, moveKeys) {        
      this.optionsSelected = nextTargetKeys;
    },
    handleSearch(dir, value) {
      return value.toUpperCase();
    },
    handleSelectChange(sourceSelectedKeys, targetSelectedKeys) {        
      this.selectedKeys = [...sourceSelectedKeys, ...targetSelectedKeys];
    },

    filterOption(inputValue, option) {
      return option.title.toUpperCase().indexOf(inputValue.toUpperCase()) > -1;
    },

    updateOptions() {
        let changes = []

        this.optionsSelected.reverse()

        this.optionsSelected.forEach((productId, index) => {
                if (this.optionsAdded.find(item => item.productId == productId)){
                    changes.push(this.optionsAdded.find(item => item.productId == productId))
                } else {
                    changes.push({
                        '.key' : `new-${Math.random()}`,
                        active : true,
                        deleted : false,
                        createdAt : '',
                        createdBy: this.$store.state.user['.key'],
                        overridePrice : true,
                        price : 0,
                        position: index + 1,
                        isNew : true,
                        productId : productId
                    })
                    
                }
        })
    
        this.$emit('updated', changes)
    }

  },
  async mounted(){
    this.allProductsAddons = this.allOptions.map(item => {
        return {
            title : item.name,
            key : item['.key']
        }
    })

    this.optionsSelected = this.optionsAdded.map(item => {
        return item.productId
    })

    
  }
}
</script>