<template>
  <div>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-overlay class="loading-center" :value="save">
      <!-- <lottie
        :options="defaultOptions"
        :height="300"
        :width="400"
        v-on:animCreated="handleAnimation"
      /> -->
      <v-progress-circular
        :width="3"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <div v-if="products">
      <v-container fluid class="fill-height">
        <v-row class="pa-0 ma-0">
          <v-col cols="12" md="7">
            <h1>
              Productos de
              <span class="font-weight-light">
                {{ getBusinessName }}
              </span>
            </h1>
          </v-col>

          <v-col cols="4" md="5" class="text-right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  fab
                  v-on="on"
                  class="mr-1 elevation-animations"
                  dark
                  @click="excelExport"
                  :elevation="0"
                  :minHeight="56"
                  :loading="loading"
                >
                  <v-icon x-large dark> fa-file-excel </v-icon>
                </v-btn>
              </template>
              <span>Exportar data a .CSV</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="7">
            <v-autocomplete
              v-model="tagsToSearchSection"
              :items="sortedSections"
              prepend-icon="fa-filter"
              label="Seleccione una categoría"
              item-text="text"
              item-value="value"
              aria-autocomplete="false"
              @change="getCategoryProducts"
              autocomplete="off"
              hide-details
              filled
              rounded
              :allow-overflow="false"
              clearable
            >
              <template v-slot:item="data">
                <template v-if="checkNotObject(data)">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>

                <template v-else>
                  <v-list-item-avatar size="8" class="mr-2">
                    <span class="white--text headline"></span>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ data.item.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="12" sm="5" md="5">
            <v-row justify="end">
              <v-btn
                color="primary"
                class="ma-2 white--text mr-5 elevation-animations"
                elevation="0"
                @click="
                  modalSort = true;
                  randomKey = Math.random();
                "
                :loading="loading"
              >
                <v-icon right dark class="mr-3"> fas fa-bars </v-icon>
                Ordenar
              </v-btn>

              <v-btn
                color="primary"
                class="ma-2 white--text elevation-animations"
                elevation="0"
                @click="
                  modalAddProduct = true;
                  randomKey = Math.random();
                "
                :loading="loading"
              >
                <v-icon right dark class="mr-3"> fas fa-plus </v-icon>
                Crear nuevo producto
              </v-btn>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="12" class="mt-10">
            <!-- <h2 class="font-weight-medium ml-5 mt-10">
              {{ getMenuSectionName(tagsToSearchSection) }}
            </h2> -->
            <v-data-table
              :headers="headers"
              :items="products"
              :items-per-page="15"
              fixed-header
              :search="$store.state.search"
              :calculate-widths="true"
              sort-by="position"
              :loading="loading"
              :expanded.sync="expanded"
              :sort-asc="true"
              show-expand
              :single-expand="true"
              item-key="id"
              height="75vh"
              :footer-props="{
                itemsPerPageOptions: [10, 25, 50, 100, 250],
              }"
            >
              <template v-slot:[`item.addon`]="{ item }">
                <v-switch
                  @change="
                    switchControlChanged({ addon: item.addon }, item, 'addon')
                  "
                  v-model="item.addon"
                ></v-switch>
              </template>

              <template v-slot:[`item.isAvailable`]="{ item }">
                <v-switch
                  @change="
                    switchControlChanged(
                      { isAvailable: item.isAvailable },
                      item,
                      'isAvailable'
                    )
                  "
                  v-model="item.isAvailable"
                ></v-switch>
              </template>

              <template v-slot:[`item.adults`]="{ item }">
                <v-switch
                  @change="
                    switchControlChanged(
                      { adults: item.adults },
                      item,
                      'adults'
                    )
                  "
                  v-model="item.adults"
                ></v-switch>
              </template>

              <template v-slot:[`item.isFreeDelivery`]="{ item }">
                <v-switch
                  @change="
                    switchControlChanged(
                      { isFreeDelivery: item.isFreeDelivery },
                      item,
                      'isFreeDelivery'
                    )
                  "
                  v-model="item.isFreeDelivery"
                ></v-switch>
              </template>

              <template v-slot:[`item.isPromo`]="{ item }">
                <v-switch
                  @change="
                    switchControlChanged(
                      { isPromo: item.isPromo },
                      item,
                      'isPromo'
                    )
                  "
                  v-model="item.isPromo"
                ></v-switch>
              </template>

              <template v-slot:[`item.isPopular`]="{ item }">
                <v-switch
                  @change="
                    switchControlChanged(
                      { isPopular: item.isPopular },
                      item,
                      'isPopular'
                    )
                  "
                  v-model="item.isPopular"
                ></v-switch>
              </template>

              <template v-slot:[`item.active`]="{ item }">
                <v-switch
                  class="mr-10"
                  @change="
                    switchControlChanged(
                      { active: item.active },
                      item,
                      'active'
                    )
                  "
                  v-model="item.active"
                ></v-switch>
              </template>

              <template v-slot:[`item.menu`]="{ item }">
                <v-row v-if="item.menu && item.menu.length == 0">
                  <v-col cols="12">
                    <v-chip
                      class="mr-2"
                      color="gray"
                      style="
                        min-width: 100%;
                        max-width: 100%;
                        justify-content: center;
                      "
                    >
                      <span
                        style="
                          text-overflow: ellipsis;
                          white-space: nowrap;
                          width: 90%;
                          max-width: 90%;
                        "
                      >
                        No asignada
                      </span>
                    </v-chip>
                  </v-col>
                </v-row>

                <v-row v-if="item.menu && item.menu.length > 0">
                  <v-chip
                    class="mr-2"
                    :color="item.menu.length > 0 ? 'gray' : 'gray'"
                    style="
                      min-width: 100%;
                      max-width: 100%;
                      justify-content: center;
                    "
                  >
                    <span
                      style="
                        min-width: 100%;
                        max-width: 100%;
                        overflow-x: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      "
                    >
                      {{ getMenuNames(item.menu[0]) }}
                    </span>
                  </v-chip>
                </v-row>
              </template>

              <template v-slot:[`item.imagen`]="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar>
                      <img
                        style="object-fit: cover"
                        :src="item.images[0] || ochoLogoPlaceholder"
                        :alt="item.name"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </v-avatar>
                  </template>
                  <v-list rounded>
                    <v-list-item-group color="primary">
                      <v-list-item v-if="item.images[0]">
                        <v-list-item-title>
                          <a @click.prevent="downloadImage(item)">
                            Descargar imagen
                          </a>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </template>

              <template v-slot:[`item.price`]="{ item }">
                <span>{{ item.price | toCurrency }}</span>
              </template>

              <template v-slot:[`item.options`]="{ item }">
                <v-btn
                  @click="editProduct(item)"
                  class="mr-1"
                  small
                  color="primary"
                  elevation="0"
                >
                  Editar
                </v-btn>
                <v-btn
                  @click="cloneProduct(item)"
                  class="mr-1"
                  small
                  elevation="0"
                  color="primary"
                >
                  Clonar
                </v-btn>
                <v-btn
                  @click="addAddons(item)"
                  class="mr-1"
                  small
                  elevation="0"
                  color="primary"
                >
                  Configurar
                </v-btn>
                <v-btn
                  @click="
                    itemToDelete = item;
                    modalDelete = true;
                  "
                  small
                  color="red darken-4 mr-1"
                  class="white--text"
                  elevation="0"
                >
                  Eliminar
                </v-btn>
              </template>

              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pa-4">
                  <product-details
                    :key="item.id"
                    :products="products"
                    :product="item"
                  ></product-details>
                </td>
              </template>

              <template v-slot:loading>
                <div class="ma-5" style="position: relative">
                  <div class="lottie-wrapper">
                    <!-- <lottie
                      :options="defaultOptions"
                      :height="300"
                      :width="400"
                      v-on:animCreated="handleAnimation"
                    /> -->
                    <v-progress-circular
                      :width="3"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                    <p
                      :class="
                        $vuetify.theme.dark
                          ? 'subtitle-1 primary--text'
                          : 'subtitle-1 black--text'
                      "
                    >
                      Cargando Data
                    </p>
                  </div>

                  <v-skeleton-loader
                    ref="skeleton"
                    type="table-tbody"
                    class="mx-auto d-none d-md-block"
                  >
                  </v-skeleton-loader>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-dialog v-model="modalEditProduct" max-width="70%">
      <edit-product
        @goToConfiguration="goToConf"
        @success="updateProductSuccess"
        @cancel="modalEditProduct = false"
        :key="productSelected.id + randomKey"
        :product="productSelected"
      ></edit-product>
    </v-dialog>

    <v-dialog max-width="550px" v-model="modalEditAddons">
      <edit-addons
        @addonUpdated="addonUpdated"
        :key="productSelected.id"
        @cancel="modalEditAddons = false"
        :products="products"
        :product="productSelected"
      ></edit-addons>
    </v-dialog>

    <v-dialog persistent v-model="modalAddProduct" max-width="70%">
      <new-product
        :key="randomKey"
        @cancel="modalAddProduct = false"
        :selectedSection="tagsToSearchSection"
        @success="productCreated"
      ></new-product>
    </v-dialog>

    <v-dialog scrollable persistent v-model="modalSort" max-width="500px">
      <sort
        :key="`sort-${randomKey}`"
        :products="products"
        @cancel="modalSort = false"
      ></sort>
    </v-dialog>

    <!-- modal delete  -->
    <v-dialog max-width="400" v-model="modalDelete" v-if="itemToDelete">
      <v-card>
        <v-card-title class="headline">
          Confirmar acción
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="modalDelete = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="mt-3">
          ¿Está seguro que desea eliminar
          <span class="font-weight-bold"> {{ itemToDelete.name }} </span>?
        </v-card-text>

        <v-card-actions class="mt-2" style="justify-content: center">
          <v-btn
            color="red darken-4"
            width="90%"
            style="border-radius: 50px"
            class="mb-3"
            dark
            @click="confirmDelete"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      scrollable
      persistent
      v-model="cloneDialog"
      v-if="cloneDialog"
      max-width="700px "
    >
      <clone
        @success="handleClone"
        :product="productSelected"
        @cancel="cloneDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import moment from "moment-timezone";
import clone from "./clone";
import "moment/locale/es";
moment.locale("es");
import { mapState } from "vuex";
import productDetails from "./product-details";
import productEdit from "./edit-product";
import editaAddons from "./edit-addons";
import newProduct from "./new-product";
import sort from "./sort";
import axios from "axios";
import ledgerMixin from "@/mixins/ledgerMixin";

export default {
  name: "products",
  mixins: [lottieSettings, ledgerMixin],
  components: {
    lottie: Lottie,
    "product-details": productDetails,
    "edit-product": productEdit,
    "edit-addons": editaAddons,
    "new-product": newProduct,
    sort,
    clone,
  },
  data() {
    return {
      business: {},
      item: 0,
      products: [],
      randomKey: 0,
      time: null,
      menu2: false,
      moveModal: false,
      daySelected: "monday",
      itemToDelete: null,
      modalDelete: false,
      modalEditProduct: false,
      productSelected: [],
      modalEditAddons: false,
      searchableTags: [
        { name: "Activo", color: "lime darken-1" },
        { name: "Disponible", color: "green" },
        { name: "Addon", color: "amber" },
      ],
      headers: [
        { text: "Posición", value: "position", class: "nowrap" },
        { value: "imagen" },
        { text: "SKU", value: "sku", class: "nowrap" },
        { text: "Nombre", value: "name", width: "200px", class: "nowrap" },
        { text: "Sección", value: "menu", class: "nowrap" },
        { text: "Precio", value: "price", width: "100px" },
        { text: "Disponible en el app", value: "isAvailable" },
        { text: "Prohibida la venta a menores", value: "adults" },
        { text: "Visible en el app", value: "active" },
        {
          text: "Aplica delivery gratis",
          value: "isFreeDelivery",
          align: this.$store.state.user.type == "sudo" ? "" : " d-none",
        },
        {
          text: "Producto en promoción",
          value: "isPromo",
          align: this.$store.state.user.type == "sudo" ? "" : " d-none",
        },
        {
          text: "Pupular",
          value: "isPopular",
          align: this.$store.state.user.type == "sudo" ? "" : " d-none",
        },
        { value: "options", align: "end", width: "450px", sortable: false },
      ],
      loading: true,
      expanded: [],
      menu: [],
      setupSchedule: false,
      rules: [(value) => !!value || "Required."],
      modalAddProduct: false,
      newItem: {
        active: true,
        addon: false,
        addonPrice: 0,
        addons: [],
        adults: false,
        isFreeDelivery: false,
        barcode: "",
        business: [],
        calories: 0,
        deleted: false,
        description: "",
        disclaimer: "",
        discount: 0,
        expires: false,
        expiresOn: fb.firestore.Timestamp.fromDate(this.getTime()),
        createdAt: fb.firestore.Timestamp.fromDate(this.getTime()),
        createdBy: this.$store.state.user[".key"],
        featured: false,
        fractionable: false,
        height: 0,
        instructions: "",
        isAvailable: true,
        menu: [],
        minStock: 0,
        name: "",
        ochoActive: true,
        owner: "ocho",
        presentations: false,
        price: 0,
        rating: 0,
        schedule: [],
        sizeUnits: "",
        sold: 0,
        stock: 0,
        tags: [""],
        takeOutCost: "",
        tax: 0.15,
        timeToProcess: 0,
        url: "",
        images: [],
        userLimit: false,
        userMax: 0,
        visible: true,
        visits: 0,
        volume: 0,
      },
      snackbar: false,
      snackbarText: "",
      menuSections: [],
      cloneDialog: false,
      menuSelected: null,
      tagsToSearch: null,
      tagsToSearchSection: null,
      search: "",
      save: false,
      imageFile: null,
      imgPreview: null,
      copyProducts: [],
      modalSort: false,
      ochoLogoPlaceholder:
        "https://firebasestorage.googleapis.com/v0/b/el-ocho-data/o/product-default.jpg?alt=media&token=2936fc1f-b1de-49f8-81dd-8ad132ef52df",
    };
  },
  filters: {
    toCurrency(value) {
      if (typeof value !== "number") return value;

      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });

      return formatter.format(value);
    },
  },
  watch: {
    selectedBusiness() {
      this.products = [];
      this.getBusinessCategories();
    },
    products() {
      this.products.map((item) => {
        let product = item;
        product.id = item[".key"];
        return product;
      });
    },
  },
  computed: {
    ...mapState(["selectedBusiness"]),

    getBusinessName() {
      return this.$store.state.selectedBusiness.shortName;
    },

    sortedSections() {
      function compare(a, b) {
        if (a.text < b.text) return -1;
        if (a.text > b.text) return 1;
        return 0;
      }

      return [
        ...this.menuSections.sort(compare),
        ...[
          {
            text: "Mostrar todos los productos",
            value: "all",
          },
        ],
      ];
    },
  },
  methods: {
    getSectionToken() {
      return new Promise((resolve, reject) => {
        fb.auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            resolve(token);
          });
      });
    },
    async excelExport() {
      this.generatingReport = true;
      let token = await this.getSectionToken();
      let data = {
        businessId: this.selectedBusiness[".key"],
      };

      let func = "httpExcelMenuhnProductsFile";

      axios
        .post(`${process.env.VUE_APP_EL_OCHO}/${func}`, data, {
          headers: { Authorization: `Basic ${token}` },
          responseType: "blob",
        })
        .then((response) => {
          this.generatingReport = false;
          const { data, headers } = response;
          const fileName = `Lista productos de ${this.selectedBusiness.shortName}.xlsx`;

          const blob = new Blob([data], { type: headers["content-type"] });
          let dom = document.createElement("a");
          let url = window.URL.createObjectURL(blob);
          dom.href = url;
          dom.download = decodeURI(fileName);
          dom.style.display = "none";
          document.body.appendChild(dom);
          dom.click();
          dom.parentNode.removeChild(dom);
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          this.generatingReport = false;
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
        });
    },

    cloneProduct(item) {
      this.productSelected = item;
      this.cloneDialog = true;
      // console.log(this.productSelected);
    },

    handleClone() {
      this.cloneDialog = false;
      this.snackbar = true;
      this.snackbarText = "Producto clonado exitosamente.";
    },
    goToConf: function () {
      this.modalEditProduct = false;
      this.modalEditAddons = true;
    },
    getMenuNames: function (id) {
      if (id && this.menuSections.length != 0) {
        return this.menuSections.find((menu) => menu.value == id)
          ? this.menuSections.find((menu) => menu.value == id).text
          : "No asignada";
      } else {
        return "No asignada";
      }
    },
    getTime() {
      let timeArr = moment()
        .tz("America/Tegucigalpa")
        .format("YYYY, MM, DD, HH, mm, ss, 0")
        .split(",");
      return new Date(
        parseInt(timeArr[0]),
        parseInt(timeArr[1]) - 1,
        parseInt(timeArr[2]),
        parseInt(timeArr[3]),
        parseInt(timeArr[4]),
        parseInt(timeArr[5]),
        parseInt(timeArr[6])
      );
    },
    updateProductSuccess: function () {
      this.snackbarText = "Información actualizada correctamente";
      this.snackbar = true;
      this.modalEditProduct = false;
    },
    addonUpdated: function () {
      this.snackbarText = "Información actualizada correctamente";
      this.snackbar = true;
    },
    editProduct: function (item) {
      this.productSelected = item;
      this.randomKey = Math.random();
      this.modalEditProduct = true;
    },
    addAddons(item) {
      this.productSelected = item;
      this.modalEditAddons = true;
    },
    productCreated: function () {
      this.modalAddProduct = false;
      this.snackbarText = "Producto creado exitosamente";
      this.snackbar = true;
    },
    confirmDelete: function () {
      if (this.itemToDelete) {
        db.collection("qrProducts")
          .doc(this.itemToDelete.id)
          .update({
            deleted: true,
            active: false,
            deletedAt: fb.firestore.Timestamp.fromDate(this.getTime()),
            deletedBy: this.$store.state.user[".key"],
          })
          .then(() => {
            this.modalDelete = false;
            this.snackbar = true;
            this.snackbarText = "Producto eliminado";
          })
          .catch(() => {
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },
    switchControlChanged(newValue, item, field) {
      let prevData = Object.assign({}, newValue);
      prevData[field] = !newValue[field];

      let freeShippingProductsIsAvailable = false;
      if (newValue.isFreeDelivery) {
        freeShippingProductsIsAvailable = true;
        db.collection("businesses").doc(this.selectedBusiness[".key"]).update({
          freeShippingProductsIsAvailable,
        });
      } else if (newValue.isFreeDelivery == false) {
        db.collection("qrProducts")
          .where("business", "array-contains", this.selectedBusiness[".key"])
          .where("deleted", "==", false)
          .where("active", "==", true)
          .get()
          .then((doc) => {
            doc.forEach((element) => {
              if (element.data().isFreeDelivery) {
                freeShippingProductsIsAvailable = true;
              }
            });
            db.collection("businesses")
              .doc(this.selectedBusiness[".key"])
              .update({
                freeShippingProductsIsAvailable,
              });
          });
      }

      this.save = true;

      db.collection("qrProducts")
        .doc(item.id)
        .update(newValue)
        .then(() => {
          this.save = false;
          this.snackbarText = "Información del producto actualizada.";
          this.snackbar = true;

          this.createLedger({
            source_id: item.id,
            source_type: "products",
            currentValue: newValue,
            prevValue: prevData,
          });
        })
        .catch(() => {
          if (newValue.active != undefined) item.active = !newValue.active;
          else if (newValue.addon != undefined) item.addon = !newValue.addon;
          else if (newValue.isPromo != undefined)
            item.isPromo = !newValue.isPromo;
          else if (newValue.isAvailable != undefined)
            item.isAvailable = !newValue.isAvailable;
          else if (newValue.adults != undefined) item.adults = !newValue.adults;
          else if (newValue.isFreeDelivery != undefined)
            item.isFreeDelivery = !newValue.isFreeDelivery;

          this.save = false;
          this.snackbarText = "Ocurrió un error, inténtelo nuevamente.";
          this.snackbar = true;
        });
    },
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      let excelHeader = {
        SKU: "",
        "Nombre del producto": "",
        Precio: "",
        Destacados: "",
        Disponible: "",
        "Contenido para adultos": "",
        "Aplica a envio gratis": "",
        Activo: "",
      };

      csvContent += [
        Object.keys(excelHeader).join(";"),
        ...arrData.map(
          (item) =>
            `${item.sku ? item.sku : "No asignado"};${item.name};${
              item.price
            };${item.featured ? "Si" : "No"};${
              item.isAvailable ? "Si" : "No"
            };${item.adults ? "Si" : "No"};${
              item.isFreeDelivery ? "Si" : "No"
            };${item.active ? "Si" : "No"};`
        ),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");
      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute(
        "download",
        `${this.getBusinessName}_productos(${moment()
          .tz("America/Tegucigalpa")
          .format("DD-MM-YYYY")}).csv`
      );
      link.click();
    },

    async getCategoryProducts(e) {
      this.products = [];
      this.loading = true;

      let query =
        e != "all"
          ? db
              .collection(`qrProducts`)
              .where("deleted", "==", false)
              .where("addon", "==", false)
              .where("menu", "array-contains", e)
          : db
              .collection(`qrProducts`)
              .where("deleted", "==", false)
              .where("addon", "==", false)
              .where(
                "business",
                "array-contains",
                this.selectedBusiness[".key"]
              );

      let productsQuery = this.$binding("products", query);

      await Promise.all([productsQuery]);

      this.products.map((item) => {
        let product = item;
        product.id = item[".key"];
        return product;
      });

      this.loading = false;
    },
    async getBusinessCategories() {
      // if (!this.selectedBusiness) {
      //   return this.$router.push({ path: "/" });
      // }

      this.loading = true;
      this.tagsToSearchSection = "";
      this.$store.state.search = "";
      // this.menuSections = [

      // ];

      // let productsQuery = this.$binding(
      //   "products",
      //   db
      //     .collection(`products`)
      //     .where("business", "array-contains", this.selectedBusiness[".key"])
      //     .where("deleted", "==", false)
      //     .where("addon", "==", false)
      // );

      // await Promise.all([productsQuery]);
      // this.products.map((item) => {
      //   let product = item;
      //   product.id = item[".key"];
      //   return product;
      // });

      await db
        .collection(`businesses/${this.selectedBusiness[".key"]}/qrMenu`)
        .get()
        .then((menuSubcollection) => {
          menuSubcollection.forEach((subcollection) => {
            if (!subcollection.data().deleted)
              this.menuSections.push({
                text: subcollection.data().name,
                value: subcollection.id,
              });
          });

          // this.menuSections.push({
          //   text: " Todos",
          //   value: "all",
          // });
        });

      this.loading = false;
    },
    checkNotObject(data) {
      return typeof data.item !== "object";
    },
    downloadImage(item) {
      axios.get(item.images[0], { responseType: "blob" }).then((response) => {
        this.snackbarText = "Generando imagen, por favor esperar";
        this.snackbar = true;
        const blob = new Blob([response.data], { type: "image/png" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${item.name.trim()}-imagen`;
        link.click();
        URL.revokeObjectURL(link.href);
      });
    },
    getMenuSectionName(tag) {
      if (!tag) {
        return "Todos";
      } else {
        return this.menuSections.filter((object) => {
          if (object.value === tag) {
            return object;
          }
        })[0].text;
      }
    },
  },
  mounted() {
    // if (!this.selectedBusiness) {
    //   return this.$router.push({ path: "/" });
    // }

    this.$store.state.visibleSearch = true;
    this.$store.state.searchTerm = "productos";
    this.getBusinessCategories();
  },
};
</script>

<style lang="scss">
@import "@/main.scss";
.table-sections {
  margin: 5%;
}
.add-schedule {
  display: flex;
  justify-content: center;
}
.fa-edit {
  cursor: pointer;
}
.close-modal {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.grid-close {
  display: grid;
  grid-template-columns: auto auto;
}
.fa-times {
  color: gray;
}
.img-preview img {
  max-width: 100%;
}
.lottie-wrapper {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.theme--light.v-list {
  background: #fff;
}
</style>
