<template>
  <div>
    <v-snackbar top :timeout="3000" v-if="snackbar" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay class="loading-center over-ce"  :value="saving">
      <!-- <lottie
        :options="defaultOptions"
        :height="300"
        :width="400"
        v-on:animCreated="handleAnimation"
      /> -->
                        <v-progress-circular
      :width="3"
      color="primary"
      indeterminate
    ></v-progress-circular>
    </v-overlay>

    <v-card class="pa-5 configur">
      <v-card-title class="grid-close">
        <p>
          Sección: {{ addon.name }} <br />
          Producto: {{ product.name }} <br />
          Precio:
          <v-chip color="gray" class="mb-2">
            {{ product.price | toCurrency }}
          </v-chip>
        </p>

        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>

      <div>
        <v-row class="">
          <v-col class="border-s" cols="12">
            <!-- <p>
                Configuración de opciones de la sección de complementos de
                producto escogida
              </p>
              <v-divider class="mb-3"></v-divider> -->

            <v-row class="pa-4">
              <v-col cols="6">
                <v-row>
                  <v-col cols="12" md="12">
                    <p>Nombre de la sección</p>
                    <input
                      class="control-input"
                      type="text"
                      placeholder="Ingrese el nombre de la sección"
                      v-model="editedAddon.displayName"
                    />
                  </v-col>

                  <v-col cols="12" md="12">
                    <p>
                      ¿Cuántas opciones puede escoger el usuario para esta
                      sección?
                    </p>
                    <input
                      class="control-input control-input-number"
                      @keypress="isNumber($event)"
                      type="number"
                      placeholder="Cantidad máxima de opciones"
                      v-model.number="editedAddon.max"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row class="border-s">
                  <v-col class="mt-5" cols="12" md="12">
                    <v-switch
                      v-model="editedAddon.required"
                      label="Obligatorio"
                    ></v-switch>
                  </v-col>

                  <v-col class="mt-" cols="12" md="12">
                    <v-switch
                      v-model="editedAddon.active"
                      label="Visible en el app"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row class="mt-3 pa-2 border-s">
              <v-col cols="12" md="6" sm="12">
                <p class="title ma-0" style="color: #00cdbc">
                  Opción de complementos disponibles para la sección
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-row>
                  <v-col cols="5">
                    <v-btn
                      color="primary"
                      :disabled="productsInAddon.length < 2"
                      class="white--text"
                      @click="orderOptions = true"
                      :loading="loading"
                    >
                      <v-icon right dark class="mr-3">fas fa-bars</v-icon>
                      Ordenar
                    </v-btn>
                  </v-col>

                  <v-col cols="6">
                    <v-btn
                      color="primary"
                      class="white--text"
                      @click="moveAndShow"
                    >
                      <v-icon right dark class="mr-3">fas fa-plus</v-icon>
                      Escoger opciones
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="12" sm="12">
                <v-data-table
                  :headers="headers"
                  :items="productsInAddon"
                  :items-per-page="5"
                  :loading="loading"
                  :sort-by="['position']"
                  :sort-desc="[false, true]"
                  :footer-props="{
                    itemsPerPageOptions: [10, 25, 50, 100, 250],
                  }"
                >
                  <template v-slot:[`item.active`]="{ item }">
                    <v-switch v-model="item.active"></v-switch>
                  </template>

                  <template v-slot:[`item.productId`]="{ item }">
                    {{ getProductName(item.productId) }}
                  </template>

                  <template v-slot:[`item.position`]="{ item }">
                    {{ item.position }}
                  </template>

                  <template v-slot:[`item.price`]="{ item }">
                    <v-text-field
                      class="new-price"
                      @keypress="isNumber($event, item.price)"
                      v-model.number="item.price"
                      type="number"
                      required
                    ></v-text-field>
                  </template>

                  <template v-slot:[`item.businessPrice`]="{ item }">
                    <v-text-field
                      class="new-price"
                      @keypress="isNumber($event, item.businessPrice)"
                      v-model.number="item.businessPrice"
                      type="number"
                      required
                    ></v-text-field>
                  </template>

                  <template v-slot:[`item.total`]="{ item }">
                    {{ (item.price + product.price) | toCurrency }}
                  </template>

                  <template v-slot:loading>
                    <div class="ma-5" style="position: relative">
                      <div class="lottie-wrapper">
                        <lottie
                          :options="defaultOptions"
                          :height="300"
                          :width="400"
                          v-on:animCreated="handleAnimation"
                        />
                        <p
                          :class="
                            $vuetify.theme.dark
                              ? 'subtitle-1 primary--text'
                              : 'subtitle-1 black--text'
                          "
                        >
                          Cargando Data
                        </p>
                      </div>
                      <v-skeleton-loader
                        ref="skeleton"
                        type="table-tbody"
                        class="mx-auto d-none d-md-block"
                      >
                      </v-skeleton-loader>
                    </div>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <!-- <v-divider class="mt-3"></v-divider> -->
            <v-row justify="end">
              <v-btn
                @click="updateAddon"
                color="primary"
                class="mt-3 save-btn"
                dark
              >
                Guardar
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <!-- modal delete  -->
    <v-dialog max-width="350" v-if="modalDelete" v-model="modalDelete">
      <v-card>
        <v-card-title class="headline"> Confirmar acción </v-card-title>

        <v-card-text>
          ¿Está seguro que desea eliminar este elemento?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="confirmDelete()">
            Eliminar
          </v-btn>
          <v-btn color="primary" text @click="modalDelete = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="350" v-if="orderOptions" v-model="orderOptions">
      <v-card>
        <v-card-title >
         <b>Ordenar opciones</b> 
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="orderOptions = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <draggable
            ghost-class="ghost"
            v-model="productsInAddon"
            @start="drag = true"
            @end="drag = false"
          >
            <transition-group type="transition" name="flip-list">
              <v-list-item
                style="cursor: move"
                v-for="item in productsInAddon"
                class="list-group-item"
                :key="item['.key']"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ getProductName(item.productId) }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-icon>
                  <v-icon>fas fa-bars</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </transition-group>
          </draggable>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn
                    color="primary"
                    text
                    @click="cancelReorder()">
                    Cancelar
                </v-btn> -->
          <v-btn color="primary" block class="mb-5" @click="changeOrder()">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      persistent
      v-if="modalShowOptions"
      v-model="modalShowOptions"
      max-width="700px"
    >
      <add-remove-options
        @cancel="modalShowOptions = false"
        :key="randKey"
        :addon="addon"
        @updated="handleOptionsUpdated"
        :allOptions="allProductsAddons"
        :optionsAdded="productsInAddon"
      >
      </add-remove-options>
    </v-dialog>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapState } from "vuex";
import addRemoveOptions from "./add-remove-options";
import draggable from "vuedraggable";
import ledgerMixin from "@/mixins/ledgerMixin";

export default {
  name: "addon-options",
  props: ["addon", "products", "product"],
  mixins: [lottieSettings, ledgerMixin],
  components: {
    lottie: Lottie,
    addRemoveOptions,
    draggable,
  },
  data() {
    return {
      orderOptions: false,
      productsInAddon: [],
      editedAddon: {},
      saving: false,
      snackbar: false,
      snackbarText: "",
      headers: [
        { text: "Posición", value: "position", align: "center" },
        { text: "Nombre", value: "productId" },
        // { text: "Precio en el comercio", value: "businessPrice" },
        { text: "Precio", value: "price", align: "center" },
        { text: "Precio total del producto", value: "total", align: "center" },
        { text: "Visible en el app", value: "active" },
      ],
      modalDelete: false,
      itemToDelete: {},
      loading: true,
      allProducts: [],
      allProductsAddons: [],
      modalShowOptions: false,
      randKey: 0,
    };
  },
  filters: {
    toCurrency(value) {
      if (typeof value !== "number") return value;

      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
  computed: {
    ...mapState(["selectedBusiness"]),

    sortedArrayProducts: function () {
      function compare(a, b) {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
      }

      return this.productsInAddon.sort(compare);
    },
    validateAddon() {
      this.editedAddon.max = Number.parseInt(this.editedAddon.max);

      this.editedAddon.min = this.editedAddon.required
        ? Number.parseInt(this.editedAddon.max)
        : 0;

      if (!this.editedAddon.displayName) {
        this.snackbarText = "Ingrese el nombre del complemento";
        this.snackbar = true;
        return false;
      } else if (
        this.editedAddon.max < 0 ||
        Number.isNaN(this.editedAddon.max)
      ) {
        this.snackbarText =
          "El número de veces que el cliente puede escoger debe ser un número válido";
        this.snackbar = true;
        return false;
      } else {
        return true;
      }
    },
    getTypeControl() {
      let total = 0;

      this.productsInAddon.forEach((product) => {
        total += product.price;
      });

      if (this.editedAddon.max == 1) {
        return "radio";
      } else if (
        this.editedAddon.max > 1 &&
        this.productsInAddon.length > 1 &&
        total > 0
      ) {
        return "checkbox";
      } else if (this.editedAddon.max > 1 && total == 0) {
        return "spin";
      } else {
        return "radio";
      }
    },
  },
  methods: {
    changeOrder() {
      this.productsInAddon.forEach((item, index) => {
        item.position = index + 1;
      });

      this.orderOptions = false;
    },
    cancelReorder() {
      this.orderOptions = false;
      this.productsInAddon = this.sortedArrayProducts;
    },
    isNumber(evt, value) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (value && !Number.isInteger(value) && charCode == 46) {
        evt.preventDefault();
      }

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    handleOptionsUpdated(data) {
      this.modalShowOptions = false;
      this.productsInAddon = data;
    },
    moveAndShow() {
      this.randKey = Math.random();
      this.modalShowOptions = true;
    },
    getTime() {
      let timeArr = moment()
        .tz("America/Tegucigalpa")
        .format("YYYY, MM, DD, HH, mm, ss, 0")
        .split(",");
      return new Date(
        parseInt(timeArr[0]),
        parseInt(timeArr[1]) - 1,
        parseInt(timeArr[2]),
        parseInt(timeArr[3]),
        parseInt(timeArr[4]),
        parseInt(timeArr[5]),
        parseInt(timeArr[6])
      );
    },
    confirmDelete() {
      if (this.itemToDelete.element.isNew) {
        this.productsInAddon = this.productsInAddon.filter(
          (item) => item.productId != this.itemToDelete.element.productId
        );
        this.modalDelete = false;
        this.snackbarText = "Opción borrada";
        this.snackbar = true;
      } else {
        db.collection(`qrAddons/${this.addon[".key"]}/products`)
          .doc(this.itemToDelete.element[".key"])
          .update({
            deleted: true,
            active: false,
            deletedAt: fb.firestore.Timestamp.fromDate(this.getTime()),
            deletedBy: this.$store.state.user[".key"],
          })
          .then(() => {
            this.productsInAddon = this.productsInAddon.filter(
              (item) => item[".key"] != this.itemToDelete.element[".key"]
            );
            this.modalDelete = false;
            this.snackbarText = "Opción borrada";
            this.snackbar = true;

            this.createLedger({
              source_id: this.itemToDelete.element[".key"],
              source_type: "products",
              currentValue: {
                deleted: true,
                active: false,
                deletedAt: fb.firestore.Timestamp.fromDate(this.getTime()),
                deletedBy: this.$store.state.user[".key"],
              },
              prevValue: {},
            });
          })
          .catch((error) => {
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },
    updateAddon: function () {
      if (this.validateAddon) {
        this.saving = true;
        var addonRef = db.collection("qrAddons").doc(this.addon[".key"]);
        return addonRef
          .update({
            displayName: this.editedAddon.displayName,
            name: this.editedAddon.displayName,
            min: parseInt(this.editedAddon.min),
            max: parseInt(this.editedAddon.max),
            controlType: this.getTypeControl,
            required: this.editedAddon.required,
            active: this.editedAddon.active,
            position: this.editedAddon.position
              ? this.editedAddon.position
              : "",
          })
          .then(() => {
            this.updateOptions();
            this.saving = false;
            this.snackbarText = "Información actualizada correctamente.";
            this.snackbar = true;
            this.$emit("addonUpdated");

            this.createLedger({
              source_id: this.addon[".key"],
              source_type: "addons",
              currentValue: this.editedAddon,
              prevValue: this.addon,
            });
          })
          .catch((error) => {
            console.log("updating addon");
            console.log(error);
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },
    updateOptions() {
      this.productsBeforeChanges.forEach((product) => {
        if (
          !this.productsInAddon.find((item) => item[".key"] == product[".key"])
        ) {
          db.collection(`qrAddons/${this.addon[".key"]}/products`)
            .doc(product[".key"])
            .update({
              active: false,
              deleted: true,
            })
            .then(() => {
              this.createLedger({
                source_id: product[".key"],
                source_type: "products",
                currentValue: {
                  active: false,
                  deleted: true,
                },
                prevValue: {
                  active: true,
                  deleted: false,
                },
              });
            })
            .catch((err) => {
              // console.log("updating addon");
              console.log(err);
            });
        }
      });

      this.productsBeforeChanges = this.productsInAddon;

      this.productsInAddon.forEach((product) => {
        if (product.isNew) {
          delete product[".key"];
          delete product["isNew"];
          product.price = product.price ? product.price : 0;
          product.price = Number.parseFloat(product.price);
          // product.businessPrice = Number.parseFloat(product.businessPrice);
          product.createdAt = fb.firestore.Timestamp.fromDate(this.getTime());
          product.parentProductId = this.addon.parentProductId;
          product.parentProductName = this.addon.parentProductName;
          product.position = product.position ? product.position : "";

          db.collection(`qrAddons/${this.addon[".key"]}/products`)
            .add(product)
            .then((ref) => {
              product[".key"] = ref.id;

              this.createLedger({
                source_id: ref.id,
                source_type: "products",
                currentValue: product,
                prevValue: {},
              });
            })
            .catch((err) => {
              console.log(err);
              this.saving = false;
              this.snackbarText = "Ocurrió un error, inténtelo nuevamente.";
              this.snackbar = true;
            });
        } else {
          product.price = product.price ? product.price : 0;
          product.price = Number.parseFloat(product.price);
          db.collection(`qrAddons/${this.addon[".key"]}/products`)
            .doc(product[".key"])
            .update({
              active: product.active,
              overridePrice: product.overridePrice,
              price: product.price,
              businessPrice: product.businessPrice ? product.businessPrice : 0,
              parentProductId: this.addon.parentProductId,
              parentProductName: this.addon.parentProductName,
              position: product.position ? product.position : "",
            })
            .then(() => {
              const prevProduct = this.productsBeforeChanges.filter(
                (_product) => _product[".key"] == product[".key"]
              )[0];

              this.createLedger({
                source_id: product[".key"],
                source_type: "products",
                currentValue: {
                  active: product.active,
                  overridePrice: product.overridePrice,
                  price: product.price,
                  parentProductId: this.addon.parentProductId,
                  parentProductName: this.addon.parentProductName,
                  position: product.position ? product.position : "",
                },
                prevValue: {
                  active: prevProduct.active,
                  overridePrice: prevProduct.overridePrice,
                  price: prevProduct.price,
                  parentProductId: this.addon.parentProductId,
                  parentProductName: this.addon.parentProductName,
                  position: prevProduct.position ? prevProduct.position : "",
                },
              });
            });
        }
      });
    },
    getProductName(productId) {
      return this.allProductsAddons.find((prod) => prod[".key"] == productId)
        ? this.allProductsAddons.find((prod) => prod[".key"] == productId).name
        : "Producto borrado";
    },
    showModalDelete(type, element) {
      this.modalDelete = true;
      this.itemToDelete = {
        type,
        element,
      };
    },
  },
  async mounted() {
    this.editedAddon = Object.assign({}, this.addon);
    let deletedProductsQuery = db
      .collection(`qrProducts`)
      .where("business", "array-contains", this.selectedBusiness[".key"])
      .where("deleted", "==", true)
      .where("addon", "==", true);

    let deletedProducts = this.$binding(
      "allProducts",
      deletedProductsQuery
    ).then((allProducts) => {
      this.allProducts = this.allProducts.map((item) => {
        return item[".key"];
      });
    });

    await Promise.all([deletedProducts]);

    let productsInAddon = this.$binding(
      "productsInAddon",
      db
        .collection(`qrAddons/${this.addon[".key"]}/products`)
        .where("deleted", "==", false)
    ).then((productsInAddon) => {
      this.productsInAddon = this.productsInAddon.filter(
        (item) => !this.allProducts.includes(item[".key"])
      );
      this.productsInAddon = this.sortedArrayProducts; // order by position
      this.productsBeforeChanges = this.productsInAddon; //array with products to update when user changes options
    });

    let allProductsAddons = this.$binding(
      "allProductsAddons",
      db
        .collection(`qrProducts`)
        .where("business", "array-contains", this.selectedBusiness[".key"])
        .where("addon", "==", true)
        .where("deleted", "==", false)
    );
    await Promise.all([productsInAddon, allProductsAddons]);
    this.loading = false;
  },
};
</script>

<style scoped>
.over-ce {
  overflow: hidden;
}
.grid-close {
  display: grid;
  grid-template-columns: auto auto;
}
.fa-times {
  color: gray;
}
.addon-config {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.336);
}
.new-addon {
  padding: 15px;
  background-color: white;
  min-height: 200px;
}
.fa-trash-alt {
  cursor: pointer;
}
p {
  padding: 0;
  margin: 0;
  color: rgba(0, 0, 0, 0.836);
  font-size: 16px;
}
.theme--light.v-list {
  border-radius: 20px !important;
  background-color: white !important;
  padding: 10px !important;
  margin-top: 15px !important;
}
.theme--light.v-text-field--filled .v-input__control .v-input__slot {
  background: white !important;
}
.new-price >>> input {
  text-align: center;
  width: 50px !important;
}
.list-group-item {
  border-bottom: 1px solid rgba(128, 128, 128, 0.425);
}
.ghost {
  opacity: 0.5;
  background: #00cdbc4e;
}
.flip-list-move {
  transition: transform 0.5s;
}
.lottie-wrapper {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.border-s {
  border: 1px solid rgb(219, 219, 219);
  border-radius: 20px;
  margin: 2px;
}
.configur {
  height: auto;
}
</style>
