<template>
  <div class="ma-5" style="position:relative;">
    <div class="inner-lottie">
      <lottie
        :options="defaultOptions"
        :height="300"
        :width="400"
        v-on:animCreated="handleAnimation"
      />
      <p
        :class="
          $vuetify.theme.dark
            ? 'subtitle-1 primary--text'
            : 'subtitle-1 black--text'
        "
      >
        Cargando Data
      </p>
    </div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-skeleton-loader
            v-for="(item, i) of 4"
            :key="i"
            ref="skeleton"
            type="list-item"
            class="mx-auto d-none d-md-block mt-5"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";

export default {
  name: "edit-addon-skeleton",
  mixins: [lottieSettings],
  components: {
    lottie: Lottie,
  },
};
</script>

<style scoped>
.inner-lottie {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
