<template>
    <div class="controls">
        <div @click="reduce" class="reduce">-</div>
        <span class="count">{{count}}</span>
        <div @click="increase" class="increase">+</div>
    </div>
</template>

<script>
export default {
    name: "spin-control",
    props: ['max','item'],
    data() {
        return {
            count : 0
        }
    },

    methods : {
        increase (){
            if( this.count < this.max && this.item.count < this.max ){
                this.count++
                this.item.count++
                // this.$emit("increase")
            }
        },

        reduce () {
            if (this.count  > 0) {
                this.count--
                this.item.count--
                // this.$emit("reduce")
            }
        }
    }
}
</script>

<style>
    .reduce {
        border: 1px solid #00cdbc;
      display: flex;
justify-content: center;
align-items: center;
        border-radius: 20px;
        margin-right: 10px;
        width: 30px;
        height: 30px;
        cursor: pointer;
    }

    .increase {
        border: 1px solid #00cdbc;

        border-radius: 20px;
        margin-left: 10px;
        cursor: pointer;
          width: 30px;
        height: 30px;
              display: flex;
justify-content: center;
align-items: center;
    }

    .count {
        color: #00cdbc;
    }
    .controls{
        width: 100px;
        display: flex;
justify-content: space-between;
align-items: center;
    }
</style>