<template>
  <div>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
        :height="300"
        :width="400"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">Editar {{ product.name }}</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container fluid>
          <v-tabs v-model="tab" align-with-title class="mb-6">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="item in tabItems" :key="item.value">
              {{ item.text }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item key="general">
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" md="4">
                      <div style="display: grid">
                        <img
                          @click.prevent="showFileChooser"
                          :class="{ fit: existImage, 'image-product': true }"
                          :src="getUrlImage || imgDefauld"
                        />
                        <div
                          v-if="
                            product.images.length > 0 || localImages.length > 0
                          "
                          class="mb-3"
                          style="text-align: center"
                        >
                          <v-btn
                            :loading="deleting"
                            small
                            color="red darken-3 mt-2 ml-2"
                            @click="modalDelete = true"
                            class="white--text delete-image"
                          >
                            Eliminar
                          </v-btn>
                        </div>
                      </div>

                      <v-row style="justify-content: center">
                        <v-col
                          v-for="(img, index) of product.images"
                          :key="index"
                          @click="viewImage(img)"
                          cols="6"
                          md="3"
                        >
                          <v-img class="img-small" :src="img"></v-img>
                        </v-col>

                        <v-col
                          v-for="(img, index) of localImages"
                          :key="product.images.length + index"
                          @click="viewImage(img)"
                          cols="6"
                          md="3"
                        >
                          <v-img class="img-small" :src="img"></v-img>
                        </v-col>

                        <v-col cols="6" sm="3">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <div
                                v-on="on"
                                @click.prevent="showFileChooser"
                                class="add-image"
                              >
                                <i class="fas fa-plus"></i>
                              </div>
                            </template>
                            <span>Seleccionar nueva imágen</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>

                      <input
                        class="upload"
                        multiple
                        ref="input"
                        type="file"
                        name="image"
                        accept="image/*"
                        @change="imageChanged"
                      />
                    </v-col>
                    <v-col cols="12" md="8">
                      <v-row>
                        <v-col cols="12" md="6">
                          <p>SKU del producto</p>
                          <input
                            class="control-input control-input-number"
                            type="text"
                            placeholder="SKU"
                            v-model="editedProduct.sku"
                          />
                        </v-col>
                        <!-- <v-col cols="12" md="4">
                          <p>Precio del comercio</p>
                          <input
                            class="control-input control-input-number mt-8"
                            type="number"
                            @keypress="
                              isNumber($event, editedProduct.businessPrice)
                            "
                            v-model.number="editedProduct.businessPrice"
                          />
                        </v-col> -->

                        <v-col cols="12" md="6">
                          <p>Precio de venta con impuesto incluido</p>
                          <input
                            class="control-input control-input-number"
                            type="number"
                            @keypress="isNumber($event, editedProduct.price)"
                            v-model.number="editedProduct.price"
                          />
                        </v-col>
                        <v-col cols="12" md="12">
                          <p>Nombre del producto</p>
                          <input
                            class="control-input"
                            type="text"
                            placeholder="Ingrese el nombre"
                            v-model="editedProduct.name"
                          />
                        </v-col>
                        <v-col cols="12">
                          <p>Descripción <small>(500 Max)</small></p>
                          <textarea
                            @keypress="
                              maxLength($event, editedProduct.description)
                            "
                            maxlength="300"
                            placeholder="Descripción corta del producto"
                            v-model="editedProduct.description"
                            class="control-input"
                            rows="3"
                          >
                          </textarea>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-switch
                        v-model="editedProduct.isAvailable"
                        label="Disponible en el app"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-switch
                        v-model="editedProduct.adults"
                        label="Prohibida la venta a menores"
                      ></v-switch>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      v-if="$store.state.user.type == 'sudo'"
                    >
                      <v-switch
                        v-model="editedProduct.isFreeDelivery"
                        label="Aplica delivery gratis"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row align="center" justify="start">
                        <v-switch
                          label="Visible en el app"
                          v-model="editedProduct.active"
                        ></v-switch>
                      </v-row>
                    </v-col>

                    <v-col
                      v-if="$store.state.user.type == 'sudo'"
                      cols="12"
                      md="6"
                    >
                      <v-switch
                        label="Producto popular"
                        v-model="editedProduct.isPopular"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item key="sections">
              <v-row align="center">
                <v-col cols="12">
                  <div v-if="!menuSections" style="position: relative">
                    <div class="lottie-wrapper">
                      <lottie
                        :options="defaultOptions"
                        :height="300"
                        :width="400"
                        v-on:animCreated="handleAnimation"
                      />
                      <p
                        :class="
                          $vuetify.theme.dark
                            ? 'subtitle-1 primary--text'
                            : 'subtitle-1 black--text'
                        "
                      >
                        Cargando Data
                      </p>
                    </div>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-skeleton-loader
                            v-for="(item, i) of 7"
                            :key="i"
                            ref="skeleton"
                            type="list-item"
                            class="mx-auto d-none d-md-block mt-5"
                          >
                          </v-skeleton-loader>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-skeleton-loader
                            v-for="(item, i) of 7"
                            :key="i"
                            ref="skeleton"
                            type="list-item"
                            class="mx-auto d-none d-md-block mt-5"
                          >
                          </v-skeleton-loader>
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>

                  <a-transfer
                    v-if="menuSections"
                    :listStyle="{
                      height: '640px!important',
                      width: '45%',
                      background: 'white',
                    }"
                    :operationStyle="{
                      width: '8%',
                      textAlign: '-webkit-center',
                    }"
                    :data-source="menuSections"
                    :titles="['Todas las secciones', 'Secciones del producto']"
                    :target-keys="editedProduct.menu"
                    :selected-keys="selectedKeys"
                    :show-search="true"
                    @search="handleSearch"
                    :render="(item) => item.title"
                    :disabled="disabled"
                    :filter-option="filterOption"
                    @change="handleChange"
                    @selectChange="handleSelectChange"
                    :locale="{
                      itemUnit: '',
                      itemsUnit: '',
                      notFoundContent: 'Lista vacía',
                      searchPlaceholder: 'Buscar sección',
                    }"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item key="details">
              <v-row>
                <v-col cols="12" md="6">
                  <p>Unidades del producto que puede comprar un cliente</p>
                  <v-select
                    rounded
                    outlined
                    class="mt-3"
                    placeholder="Elija una opción"
                    v-model="editedProduct.userMax"
                    :items="maxAllowedOptions"
                  >
                    <template v-slot:item="data">
                      <p v-if="data.item.value == 0">Todas las que desee</p>
                      <p v-else>{{ data.item.text }}</p>
                    </template>
                    <template v-slot:selection="data">
                      <p v-if="data.item.value == 0">Todas las que desee</p>
                      <p v-else>{{ data.item.text }}</p>
                    </template>
                  </v-select>
                </v-col>

                <!-- <v-col cols="12" md="6">
                  <p>Seleccione tipo de entrega del producto</p>
                  <v-select
                    rounded
                    outlined
                    class="mt-2"
                    item-text="text"
                    item-value="value"
                    placeholder="Elija una opción"
                    v-model="editedProduct.deliveryType"
                    :items="deliveryType"
                  ></v-select>
                </v-col> -->

                <!-- <v-col cols="12" md="6">
                  <p>Días de preparación del producto</p>
                  <input
                    class="control-input control-input-number"
                    type="number"
                    :min="0"
                    @keypress="isNumber($event, editedProduct.daysPreOrder)"
                    placeholder="Días de preparación"
                    v-model.number="editedProduct.daysPreOrder"
                  />
                </v-col> -->

                <v-col cols="6">
                  <p>Cantidad en Inventario</p>
                  <input
                    class="control-input control-input-number"
                    type="number"
                    :min="0"
                    @keypress="isNumber($event, product.stockAmount)"
                    placeholder="Cantidad en invenario"
                    v-model="product.stockAmount"
                  />
                </v-col>
                <v-col cols="12">
                  <v-switch
                    class="ml-5"
                    v-model="product.isStockRequiered"
                    label="El Producto se vende bajo inventario"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-container fluid>
          <v-divider></v-divider>
          <v-row class="pa-0 ma-0">
            <v-col cols="12">
              <v-row align="center" justify="end">
                <v-btn
                  color="primary"
                  class="white--text save-btn mr-3"
                  @click="goToConf"
                >
                  Configurar producto
                </v-btn>
                <v-btn class="save-btn" color="primary" @click="updateProduct">
                  Guardar
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>

    <v-dialog max-width="400" v-model="modalDelete">
      <v-card>
        <v-card-title class="headline">
          Confirmar acción
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="modalDelete = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          ¿Está seguro que desea eliminar esta imágen?
        </v-card-text>
        <v-card-actions style="justify-content: center">
          <v-btn
            color="red darken-4"
            width="90%"
            style="border-radius: 50px"
            class="mb-3"
            dark
            @click="confirmDelete"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="confirmDelete">
            Eliminar
          </v-btn>
          <v-btn color="primary" text @click="modalDelete = false">
            Cancelar
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapState } from "vuex";
import transfer from "ant-design-vue/lib/transfer";
import ledgerMixin from "@/mixins/ledgerMixin";

export default {
  name: "section-details",
  props: ["product"],
  mixins: [lottieSettings, ledgerMixin],
  components: {
    lottie: Lottie,
    "a-transfer": transfer,
  },
  data() {
    return {
      selectedKeys: [],
      disabled: false,
      addons: [],
      modalDelete: false,
      menuSections: null,
      editedProduct: {},
      saving: false,
      snackbar: false,
      dialogModalTags: false,
      freeShippingProductsIsAvailable: false,
      business: [],
      categories: [],
      deleting: false,
      alltags: [],
      mainImage: null,
      imageFile: null,
      imgPreview: null,
      localImages: [],
      localImagesFiles: [],
      snackbarText: "",
      sectionNotInProductSelected: 0,
      sectionInProductSelected: 0,
      menuCopy: [],
      imgDefauld:'https://firebasestorage.googleapis.com/v0/b/el-ocho-data/o/product-default.jpg?alt=media&token=2936fc1f-b1de-49f8-81dd-8ad132ef52df',
      maxAllowedOptions: [],
      deliveryType: [
        { text: "Solo para delivery", value: "regular" },
        {
          text: "Solo para pasarla recogiendo (no para delivery)",
          value: "pickUp",
        },
        {
          text: "Ambas opciones aplican (delivery y recoger)",
          value: "regularPickUp",
        },
      ],
      tab: null,
      tabItems: [
        {
          text: "General",
          value: "general",
        },
        {
          text: "Secciones",
          value: "sections",
        },
        {
          text: "Detalles",
          value: "details",
        },
      ],
    };
  },
  computed: {
    ...mapState(["selectedBusiness"]),

    existImage() {
      return this.product && this.product.images && this.product.images[0]
        ? true
        : false;
    },
    getUrlImage() {
      if (this.mainImage) {
        return this.mainImage;
      } else if (
        this.product.images.length == 0 &&
        this.localImages.length > 0
      ) {
        this.mainImage = this.localImages[0];
        return this.mainImage;
      } else if (
        this.product &&
        this.product.images &&
        this.product.images[0] != ""
      ) {
        this.mainImage = this.product.images[0];
        return this.mainImage;
      } else {
        return "https://firebasestorage.googleapis.com/v0/b/el-ocho-data/o/product-default.jpg?alt=media&token=2936fc1f-b1de-49f8-81dd-8ad132ef52df";
      }
    },
  },
  mounted() {
    for (let index = 0; index < 100; index++) {
      this.maxAllowedOptions.push({ text: index, value: index });
    }

    this.editedProduct = Object.assign({}, this.product);
    this.editedProduct.menu = Object.assign([], this.product.menu);
    this.suscriptionBusiness = db
      .collection("businesses")
      .doc(this.selectedBusiness[".key"])
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.business = doc.data();
        } else {
          // console.log("No such document!");
        }
      });

    db.collection(`businesses/${this.selectedBusiness[".key"]}/qrMenu`)
      .get()
      .then((menuSubcollection) => {
        this.menuSections = [];
        menuSubcollection.forEach((subcollection) => {
          if (
            (subcollection.data().deleted == undefined ||
              subcollection.data().deleted == false) &&
            subcollection.data().name
          ) {
            this.menuSections.push({
              key: subcollection.id,
              title: subcollection.data().name,
            });
          }
        });
      });
  },
  methods: {
    goToConf() {
      this.$emit("goToConfiguration");
    },
    confirmDelete() {
      const arrayRemove = fb.firestore.FieldValue.arrayRemove;
      this.modalDelete = false;
      this.deleting = true;
      if (
        (this.mainImage && this.mainImage.startsWith("http")) ||
        this.mainImage == ""
      ) {
        db.collection("qrProducts")
          .doc(this.product.id)
          .update({
            images: arrayRemove(this.mainImage),
          })
          .then(() => {
            this.product.images = this.product.images.filter(
              (image) => image != this.mainImage
            );
            this.mainImage = this.product.images[0];
            this.snackbarText = "Imágen borrada exitosamente.";
            this.snackbar = true;

            this.createLedger({
              source_id: this.product.id,
              source_type: "products",
              currentValue: {
                images: this.product.images,
              },
              prevValue: {
                images: [...this.product.images, this.mainImage],
              },
            });

            this.deleting = false;
          });
      } else {
        let position = null;
        this.localImages.map((item, i) => {
          if (item === this.mainImage) position = i;
        });

        this.localImages = this.localImages.filter(
          (image) => image != this.mainImage
        );
        const tempFiles = this.localImagesFiles;
        this.localImagesFiles = [];
        tempFiles.forEach((item, index) => {
          if (index != position) {
            this.localImagesFiles.push(item);
          }
        });
        this.mainImage = this.product.images[0] || this.localImages[0];
        this.deleting = false;
      }
    },
    viewImage(image) {
      this.mainImage = image;
    },
    maxLength(evt, field) {
      if (field.length >= 500) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isNumber: function (evt, value) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (value && !Number.isInteger(value) && charCode == 46) {
        evt.preventDefault();
      }

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    handleChange(nextTargetKeys, direction, moveKeys) {
      this.editedProduct.menu = nextTargetKeys;
    },
    handleSearch(dir, value) {
      return value.toUpperCase();
    },
    handleSelectChange(sourceSelectedKeys, targetSelectedKeys) {
      this.selectedKeys = [...sourceSelectedKeys, ...targetSelectedKeys];
    },
    filterOption(inputValue, option) {
      return option.title.toUpperCase().indexOf(inputValue.toUpperCase()) > -1;
    },
    imageChanged(e) {
      console.log(e);
      if (e.target.files.length > 3 - this.product.images.length) {
        this.snackbarText = "Solo se permiten 3 imágenes por producto";
        this.snackbar = true;
        return;
      }

      if (!e.target.files[0]) return;
      this.localImagesFiles.push(...e.target.files);
      let ref = this;
      if (this.imageFile && this.imageFile.type.indexOf("image/") === -1) {
        console.log("tipo de archivo no permitido");
        this.imageFile = null;
      }

      // e.target.files.forEach((file) => {
      //   var reader = new FileReader();

      //   reader.onload = function (e) {
      //     ref.localImages.push(e.target.result);
      //   };
      //   if (file) reader.readAsDataURL(file);
      // });
      for (let index = 0; index < e.target.files.length; index++) {
        const file = e.target.files[index];
        var reader = new FileReader();

        reader.onload = function (e) {
          ref.localImages.push(e.target.result);
        };
        if (file) reader.readAsDataURL(file);
      }
    },
    uploadImage(productId, image) {
      return new Promise((resolve) => {
        fb.app()
          .storage(`${process.env.VUE_APP_EL_OCHO_BUCKETS}-qr-products`)
          .ref()
          .child(`${productId}/${image.name}`)
          .put(image)
          .then(() => {
            resolve("success");
          });
      });
    },
    changeBusinessStatus(value) {
      new Promise(async (resolve) => {
        await db
          .collection("businesses")
          .doc(this.selectedBusiness[".key"])
          .update({
            freeShippingProductsIsAvailable: value,
          });
        resolve("success");
      });
    },
    checkBusiness() {
      return new Promise(async (resolve) => {
        if (this.editedProduct.isFreeDelivery) {
          await this.changeBusinessStatus(true);
        } else {
          let products = await db
            .collection("products")
            .where("business", "array-contains", this.selectedBusiness[".key"])
            .where("deleted", "==", false)
            .where("active", "==", true)
            .where("isFreeDelivery", "==", true)
            .get();

          if (products.docs.length > 0) await this.changeBusinessStatus(true);
          else await this.changeBusinessStatus(false);
        }
        resolve("success");
      });
    },
    async updateProduct() {
      if (this.editedProduct.menu.length == 1 && !this.editedProduct.menu[0]) {
        this.snackbarText = "Seleccione una seccion de producto";
        this.snackbar = true;
        return;
      } else if (!this.editedProduct.name || this.editedProduct.price < 0) {
        this.snackbarText =
          "Ingrese el nombre y precio válido para el producto";
        this.snackbar = true;
        return;
      }

      if (!this.editedProduct.deliveryType) {
        this.snackbarText = "Debe seleccionar un tipo de entrega";
        this.snackbar = true;
        return;
      }

      this.editedProduct.price = Number.parseFloat(this.editedProduct.price);
      this.editedProduct.userMax = Number.parseInt(this.editedProduct.userMax);
      this.editedProduct.userLimit =
        this.editedProduct.userMax == 0 ? false : true;

      if (Number.isNaN(this.editedProduct.price)) {
        this.editedProduct.price = 0;
      }

      // this.editedProduct.sku = this.editedProduct.sku
      //   ? this.editedProduct.sku
      //   : "";

      this.saving = true;

      let data = {
        name: this.editedProduct.name,
        active: this.editedProduct.active ? this.editedProduct.active : false,
        addon: this.editedProduct.addon,
        adults: this.editedProduct.adults ? this.editedProduct.adults : false,
        isFreeDelivery: this.editedProduct.isFreeDelivery || false,
        visible: this.editedProduct.visible
          ? this.editedProduct.visible
          : false,
        isAvailable: this.editedProduct.isAvailable
          ? this.editedProduct.isAvailable
          : false,

        isPopular: this.editedProduct.isPopular
          ? this.editedProduct.isPopular
          : false,
        description: this.editedProduct.description
          ? this.editedProduct.description
          : "",
        price: this.editedProduct.price ? this.editedProduct.price : 0,
        businessPrice: this.editedProduct.businessPrice
          ? this.editedProduct.businessPrice
          : 0,
        menu: this.editedProduct.menu,
        featured: this.editedProduct.featured,
        sku: this.editedProduct.sku ? this.editedProduct.sku : "",
        userMax: this.editedProduct.userMax,
        userLimit: this.editedProduct.userLimit,
        isStockRequiered: this.editedProduct.isStockRequiered || false,
        stockAmount: this.editedProduct.stockAmount || 0,
      };

      if (this.editedProduct.deliveryType)
        data.deliveryType = this.editedProduct.deliveryType;

      if (this.editedProduct.daysPreOrder)
        data.daysPreOrder = this.editedProduct.daysPreOrder;

      db.collection("qrProducts")
        .doc(this.product.id)
        .update(data)
        .then(async () => {
          await this.checkBusiness();

          for (let i = 0; i < this.localImagesFiles.length; i++) {
            const image = this.localImagesFiles[i];
            await this.uploadImage(this.editedProduct.id, image);
          }

          this.createLedger({
            source_id: this.product.id,
            source_type: "products",
            currentValue: data,
            prevValue: {
              name: this.product.name,
              active: this.product.active,
              addon: this.product.addon,
              adults: this.product.adults,
              isFreeDelivery: this.product.isFreeDelivery || false,
              visible: this.product.visible,
              isAvailable: this.product.isAvailable,
              description: this.product.description,
              price: this.product.price,
              menu: this.product.menu,
              featured: this.product.featured,
              sku: this.product.sku,
              userMax: this.product.userMax,
              userLimit: this.product.userLimit,
              deliveryType: this.product.deliveryType || "",
              isStockRequiered: this.product.isStockRequiered || false,
              stockAmount: this.product.stockAmount || 0,
            },
          });

          this.$emit("success");
          this.localImages = [];
          this.saving = false;
        })
        .catch(() => {
          this.saving = false;
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
        });
    },
    showFileChooser() {
      if (this.product.images.length + this.localImages.length < 3) {
        this.$refs.input.click();
      } else {
        this.snackbarText = "Solo se permiten 3 imágenes por producto";
        this.snackbar = true;
      }
    },
  },
};
</script>

<style scoped>
.btn-modal-add-tags {
  justify-content: center;
}
.add-tag-ico {
  font-size: 30px !important;
  padding: 10px;
}
.close-modal {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.grid-close {
  display: grid;
  grid-template-columns: auto auto;
}
.fa-times {
  color: gray;
}
.image-product {
  max-width: 100%;
  min-width: 100%;
  max-height: 230px;
  min-height: 230px;
  cursor: pointer;
  border: 1px solid rgba(128, 128, 128, 0.062);
  border-radius: 20px;
  object-fit: cover;
}
.fit {
  object-fit: contain;
}
p {
  padding: 0;
  margin: 0;
  color: rgba(0, 0, 0, 0.836);
  font-size: 16px;
}
.theme--light.v-list {
  border: 1px solid rgba(0, 0, 0, 0.26) !important;
  border-radius: 20px !important;
  background-color: white !important;
  padding: 10px !important;
  margin-top: 15px !important;
  min-height: 500px !important;
}
.save-btn {
  margin-top: 20px;
}
.actions {
  position: fixed;
  bottom: 0;
  width: 90%;
  bottom: 20px;
  background-color: white;
}
.fixed-position {
  z-index: 100;
}
.upload {
  display: none;
}
.img-small {
  border: 1px solid rgba(0, 0, 0, 0.219);
  border-radius: 5px;
  cursor: pointer;
  max-height: 70px;
  min-height: 70px;
  object-fit: fill;
}
.add-image {
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-image i {
  font-size: 20px;
  color: #f06a26;
}
.add-image:hover {
  border-radius: 50px;
  background-color: #f069263b;
  cursor: pointer;
}
.delete-image {
  width: 50%;
}
.lottie-wrapper {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
